import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'quick-stats-widget',
  templateUrl: './quick-stats-widget.component.html',
  styleUrls: ['./quick-stats-widget.component.scss']
})
export class QuickStatsWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
