import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { IdeateHelper, IdeateValidators as CustomValidators } from '../../core/services/ideate';
import { Account } from '../../providers/account';

@Component({
  templateUrl: './change_password.html'
})
export class ChangePasswordComponent implements OnInit {

  public frmChangePassword: FormGroup;
	public validationMessages: any = {
		current_password: {
		  required: 'Please enter you current password.'
		},
		new_password: {
		  required: 'Please enter your new password.'
		},
		confirm_password: {
      equalTo: 'Please confirm your password.',
      required: 'Please enter confirm password.'
		},
	};

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public helper: IdeateHelper,
    private customValidators: CustomValidators,
    private account: Account
  ) {}

  ngOnInit() {
    this.frmChangePassword = this.formBuilder.group({
      current_password: ['', Validators.compose([Validators.required])],
      new_password: ['', Validators.compose([Validators.required])],
      confirm_password: ['', Validators.compose([Validators.required, this.customValidators.equalTo('new_password')]) ]
    });
    this.frmChangePassword.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.frmChangePassword.statusChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmChangePassword, this.validationMessages);
  }

  change_password() {
    const userInputs: any = this.frmChangePassword.value;
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.oldpassword = userInputs.current_password;
    reqParams.newpassword = userInputs.new_password;
    reqParams.confirmpassword = userInputs.confirm_password;
    this.helper.makeAPIRequest('account/change_password', reqParams).then((response) => {
      if (response.success === 1) {
        this.helper.showNotification('success', 'You just updated your password!', this.helper.config.defaultSuccessTitle);
        this.router.navigate(['/']);
      } else if (response.error === 1) {
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg , this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else if (response.errorCode === 4) {
          this.helper.showNotification('danger', 'We couldn\'t verify your current password!', this.helper.config.defaultErrorTitle);
        } else if (response.errorCode === 5) {
          this.helper.showNotification('danger', 'Please confirm your Password.', this.helper.config.defaultErrorTitle);
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
	}

}
