import { Component, OnInit } from '@angular/core';

import { IdeateHelper } from '../core/services/ideate';
import { Account } from '../providers/account';

@Component({
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    public helper: IdeateHelper,
    private account: Account
  ) { }

  ngOnInit() {
  }

}
