import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timeStampToDate'
})
export class TimeStampToDatePipe implements PipeTransform {

  transform(timeStampValue: any, format: string = 'MM-dd-yyyy hh:mm aa'): any {
    const date = timeStampValue * 1000;
    return new DatePipe('en-US').transform(date, format);
  }

}
