import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../core/services/ideate';
import { App } from '../providers/app';
import { Account } from '../providers/account';

@Component({
  templateUrl: './settings.html'
})
export class SettingsComponent implements OnInit {

  public frmSettings: FormGroup;
  public settings: any;
  public validationMessages: any = {
    service_hourly_rate: {
      required: 'Please enter hourly rate.'
    },
    managements_email: {
      required: 'Please enter hourly rate.',
      email: 'Please enter correct Email address.'
    }
  };

  constructor(
    public app: App,
    public account: Account,
    public helper: IdeateHelper,
    private router: Router,
    private formBuilder: FormBuilder,
    private customValidators: IdeateValidators
  ) { }

  ngOnInit() {
    this.getSettings();
  }

  getSettings() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    this.helper.makeAPIRequest('settings/get', reqParams).then((response) => {
      if (response.success === 1) {
        this.settings = response.data.site_settings;
        this.initForm();
      } else if (response.error === 1) {
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else if (response.errorCode === 4) {
          this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
          this.helper.navBack();
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }

  initForm() {
    this.frmSettings = this.formBuilder.group({
      service_hourly_rate: [+this.helper.getObjVal(this.settings, ['service_hourly_rate'], 0), Validators.compose([Validators.required])],
      managements_email: [this.helper.getObjVal(this.settings, ['managements_email'], ''), Validators.compose([Validators.required, Validators.email])]
    });
    this.frmSettings.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.frmSettings.statusChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmSettings, this.validationMessages);
  }

  saveSettings(timeOutInterval: number = 0) {
    setTimeout(() => {
      if (this.frmSettings.valid) {
        const userInputs: any = this.frmSettings.value;
        const reqParams: any = {};
        reqParams.user_id = this.account.info.id;
        reqParams.auth_token = this.account.info.auth_token;
        reqParams.service_hourly_rate = userInputs.service_hourly_rate;
        reqParams.managements_email = userInputs.managements_email;

        this.helper.makeAPIRequest('settings/save', reqParams).then((response) => {
          if (response.success === 1) {
            localStorage.setItem('siteSettings', JSON.stringify(response.data.site_settings));
            this.router.navigate(['/']);
          } else if (response.error === 1) {
            if (response.errorCode === 2) {
              this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
              this.account.logOut();
            } else {
              this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          } else {
            this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        }).catch((httpError) => {
          this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
        });
      }
    }, timeOutInterval);
  }
}
