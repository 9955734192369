import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';

import { IdeateHelper, IdeateValidators as CustomValidators } from '../../core/services/ideate';
import { Account } from '../../providers/account';
import { App } from '../../providers/app';

@Component({
  templateUrl: './my_account.html'
})
export class MyAccountComponent implements OnInit {

  public frmMyAccount: FormGroup;
  public validationMessages: any = {
    fname: {
      required: 'Please enter your First Name.'
    },
    lname: {
    },
    phone: {
      required: 'Please enter your Phone Number.'
    },
    address: {
      required: 'Please enter your Address.'
    },
  };

  constructor(
    public account: Account,
    public app: App,
    public helper: IdeateHelper,
    private router: Router,
    private formBuilder: FormBuilder,
    private customValidators: CustomValidators
  ) { }

  ngOnInit() {

    this.frmMyAccount = this.formBuilder.group({
      fname: [this.account.info.fname, Validators.compose([Validators.required])],
      lname: [this.account.info.lname],
      phone: [this.account.info.phone, Validators.compose([Validators.required])],
      address: [this.account.info.address, Validators.compose([Validators.required])]
    });
    this.frmMyAccount.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.frmMyAccount.statusChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmMyAccount, this.validationMessages);
  }

  update_account(timeOutInterval: number = 0) {
    setTimeout(() => {
      if (this.frmMyAccount.valid) {
        const userInputs: any = this.frmMyAccount.value;
        const reqParams: any = {};
        reqParams.user_id = this.account.info.id;
        reqParams.auth_token = this.account.info.auth_token;
        reqParams.fname = userInputs.fname;
        reqParams.lname = userInputs.lname;
        reqParams.phone = userInputs.phone;
        reqParams.address = userInputs.address;

        this.helper.makeAPIRequest('account/update_account', reqParams).then((response) => {
          if (response.success === 1) {
            this.helper.showNotification('success', 'You just updated your account!', this.helper.config.defaultSuccessTitle);
            this.account.logIn(response.data.userdata);
            this.router.navigate(['/']);
          } else if (response.error === 1) {
            if (response.errorCode === 2) {
              this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
              this.account.logOut();
            } else {
              this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          } else {
            this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        }).catch((httpError) => {
          this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
        });
      }
    }, timeOutInterval);
  }
}
