import { Pipe, PipeTransform } from '@angular/core';
import { App } from '../providers/app';

@Pipe({
  name: 'billingType'
})
export class BillingTypePipe implements PipeTransform {

  constructor(private app: App) { }

  transform(value: any): any {
    let billingTypeLabel = '';
    const billingType = this.app.billingTypes.filter((item: any) => (item.value === value));
    if (billingType.length === 1) {
      billingTypeLabel = billingType[0].label;
    }
    return billingTypeLabel;
  }

}
