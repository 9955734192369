import { Pipe, PipeTransform } from '@angular/core';
import { App } from '../providers/app';

@Pipe({
  name: 'paymentStatus'
})
export class PaymentStatusPipe implements PipeTransform {

  constructor(private app: App) { }

  transform(value: any): any {
    let paymentStatusLabel = '';
    const paymentStatus = this.app.paymentStatusTypes.filter((item: any) => (item.value === value));
    if (paymentStatus.length === 1) {
      paymentStatusLabel = paymentStatus[0].label;
    }
    return paymentStatusLabel;
  }

}
