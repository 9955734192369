import { Component, ViewEncapsulation } from '@angular/core';
import { PushNotificationsService} from 'ng-push';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'app works!';
  constructor( private pushNotifications: PushNotificationsService, private localeService: BsLocaleService ) {
    this.pushNotifications.requestPermission();
    enGbLocale.weekdaysShort = ['Su', 'Mu', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    defineLocale('en-gb', enGbLocale);
    this.localeService.use('en-gb');
  }
}
