import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';

@Component({
  selector: 'app-basic-corporate-layout',
  templateUrl: './basic-corporate.component.html',
  styleUrls: ['./basic-corporate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicCorporateLayoutComponent extends RootLayout implements OnInit {

  menuLinks = [
    {
      label: 'Dashboard',
      routerLink: '/tickets'
    },
    {
      label: 'Contracts',
      routerLink: '/contracts'
    },
    {
      label: 'Users',
      routerLink: '/users'
    },
    {
      label: 'Services',
      routerLink: '/services'
    },
    {
      label: 'Customers',
      routerLink: '/customers'
    }
  ];

  ngOnInit() {
    this.changeLayout('menu-pin');
    this.changeLayout('menu-behind');
    // Will sidebar close on screens below 1024
    this.autoHideMenuPin();
  }
}
