import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../core/services/ideate';
import { Account } from '../providers/account';

import 'rxjs/add/operator/switchMap';

@Component({
  selector: 'app-add-edit-service',
  templateUrl: './add-edit-service.component.html',
  styleUrls: ['./add-edit-service.component.scss']
})
export class AddEditServiceComponent implements OnInit {

  public userType = 'admin';
  public frmAddEdit: FormGroup;
  public recordID = 0;
  public record: any = {};
  public validationMessages: any = {
    title: {
      required: 'Title is required.'
    },
    price: {
      required: 'Price is required.'
    },
    is_add_on: {},
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public helper: IdeateHelper,
    private customValidators: IdeateValidators,
    private account: Account
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userType = params.get('type') ? params.get('type') : this.userType;
      this.recordID = (params.get('id')) ? +params.get('id') : this.recordID;
      this.getRecordData();
    });

    this.initForm();
  }

  getRecordData() {
    if (this.recordID === 0) {
      this.initForm();
    } else {
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.record_id = this.recordID;
      this.helper.makeAPIRequest('services/get', reqParams).then((response) => {
        if (response.success === 1) {
          this.record = response.data.record;
          this.initForm();
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else if (response.errorCode === 4) {
            this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
            this.helper.navBack();
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }
  }

  initForm() {
    this.frmAddEdit = this.formBuilder.group({
      title: [this.record.title, Validators.compose([Validators.required])],
      price: [this.record.price, Validators.compose([Validators.required])],
      is_add_on: [+this.record.is_add_on],
    });
    this.frmAddEdit.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmAddEdit, this.validationMessages);
  }

  save(timeOutInterval: number = 0) {
    setTimeout(() => {
      if (this.frmAddEdit.valid) {
        const userInputs: any = this.frmAddEdit.value;
        const reqParams: any = {};
        reqParams.user_id = this.account.info.id;
        reqParams.auth_token = this.account.info.auth_token;
        reqParams.record_id = this.recordID;
        reqParams.title = userInputs.title;
        reqParams.price = (userInputs.price) ? userInputs.price.toString().replace(/[^\d.-]/g, '') : 0;
        reqParams.is_add_on = userInputs.is_add_on;

        this.helper.makeAPIRequest('services/save', reqParams).then((response) => {
          if (response.success === 1) {
            this.helper.showNotification('success', 'Service info saved!', this.helper.config.defaultSuccessTitle);
            this.helper.navBack();
          } else if (response.error === 1) {
            if (response.errorCode === 2) {
              this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
              this.account.logOut();
            } else {
              this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          } else {
            this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        }).catch((httpError) => {
          this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
        });
      }
    }, timeOutInterval);
  }
}

