import { Component, ViewChild, OnInit, OnDestroy, ElementRef, HostListener, ViewEncapsulation, HostBinding } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { DataTableDirective } from 'angular-datatables';
import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/internal/operators';

import { IdeateHelper, IdeateValidators } from '../core/services/ideate';
import { App } from '../providers/app';
import { Account } from '../providers/account';
import { TicketsAddEditComponent } from '../tickets-add-edit/tickets-add-edit.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MapLocationsComponent } from '../map-locations/map-locations.component';

import { PushNotificationsService } from 'ng-push';
import RecordRTC from 'recordrtc';

declare var MediaRecorder: any;

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TicketsComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @ViewChild('takeCustomerPrompt') takeCustomerPrompt: ModalDirective;
  @ViewChild('cancelTicketModal') cancelTicketModal: ModalDirective;
  @ViewChild('reopenTicketModal') reopenTicketModal: ModalDirective;
  @ViewChild('completedTicketModal') completedTicketModal: ModalDirective;
  @ViewChild('assignMechanicModal') assignMechanicModal: ModalDirective;
  @ViewChild('ticketVideosModal') ticketVideosModal: ModalDirective;
  @ViewChild('ticketImagesModal') ticketImagesModal: ModalDirective;
  @ViewChild('ticketVoiceRecordingsModal') ticketVoiceRecordingsModal: ModalDirective;
  @ViewChild('customerTicketHistoryModal') customerTicketHistoryModal: ModalDirective;
  @ViewChild('threadImageModal') threadImageModal: ModalDirective;
  @ViewChild('threadVideoModal') threadVideoModal: ModalDirective;
  @ViewChild('threadMediaShareViaEmailModal') threadMediaShareViaEmailModal: ModalDirective;
  @ViewChild('datatable') dtElementRef: ElementRef;

  @ViewChild('mechanicsList') mechanicsList: ElementRef;
  @ViewChild('customerFilters') customerFilters: ElementRef;
  @ViewChild('customersListingContainer', { read: ElementRef }) customersListingContainer: ElementRef;
  @ViewChild('customersListHeading', { read: ElementRef }) customersListHeading: ElementRef;
  @ViewChild('datatableHead') dtHeadElementRef: ElementRef;
  @ViewChild('datatableDataBody') dtBodyElementRef: ElementRef;
  @ViewChild('ticketDetails', { read: ElementRef }) ticketDetails: ElementRef;
  @ViewChild('ticketDetailsHeading', { read: ElementRef }) ticketDetailsHeading: ElementRef;
  @ViewChild('ticketDetailsServiceInfo', { read: ElementRef }) ticketDetailsServiceInfo: ElementRef;
  @ViewChild('ticketDetailsActionAndBillings', { read: ElementRef }) ticketDetailsActionAndBillings: ElementRef;
  @ViewChild('ticketDetailsThreadHistory', { read: ElementRef }) ticketDetailsThreadHistory: ElementRef;
  @ViewChild('ticketDetailsThreadReply', { read: ElementRef }) ticketDetailsThreadReply: ElementRef;
  @ViewChild('noteThreadMedia', { read: ElementRef }) noteThreadMedia: ElementRef;

  @HostBinding('class.fileover') fileOver: boolean;
  @HostListener('dragover', ['$event']) onDragOver($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.fileOver = true;
  }
  @HostListener('dragleave', ['$event']) public onDragLeave($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.fileOver = false;
  }
  @HostListener('drop', ['$event']) public ondrop($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.fileOver = false;
    const threadMediaDropZoneDOM = document.querySelector('.thread_media_drop_zone');
    const threadMediaDropZoneOffset = threadMediaDropZoneDOM.getClientRects()[0];
    if (
      $event.clientX >= threadMediaDropZoneOffset.left &&
      $event.clientY >= threadMediaDropZoneOffset.top &&
      $event.clientX <= threadMediaDropZoneOffset.right &&
      $event.clientY <= threadMediaDropZoneOffset.bottom
    ) {
      this.noteThreadMedia.nativeElement.value = '';
      if ($event.dataTransfer.files && $event.dataTransfer.files.length == 1) {
        const fileName = $event.dataTransfer.files[0].name;
        const fileExtension = this.helper.getExtension(fileName);
        if (['jpg', 'jpeg', 'mp4', 'mov', 'wav', 'mp3', 'm4a', 'aac', 'webm'].filter((item) => item == fileExtension).length == 1) {
          this.noteThreadMedia.nativeElement.files = $event.dataTransfer.files;
        }
      }
    }
  }

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public dtColsCount = 0;
  public dtRows: any[];
  public dtInitTimeout;
  public inquiryID = 0;
  public viewTicketID = 0;
  public selectedTicket: any = {};
  public ticketThreads: any[] = [];
  public ticketsHistoryMessage = '';
  public ticketsInfoListMessage = '';
  public syncTicketsInterval;
  public dtRowsLastUpdatedTS = 0;
  public dtRequestParameters: any = {};
  public formCustomerSelection: FormGroup;

  public filterKeyword: string;
  public filterMechanic: number;
  public filterDateStart: Date | any;
  public filterDateEnd: Date | any;
  public filterShowScheduledTickets: boolean;
  public areDateFiltersEnabled = true;
  public filterStatusesAll: boolean;
  public filterStatuses: any[];
  public filterInputsChangedSub: Subject<string> = new Subject<string>();
  public ticketNote: string;
  public services: any;
  public billingManagers: any[];
  public mechanics: any[];
  public customersTicketHistory = [];
  public selectedThreadImage = '';
  public selectedThreadVideoURL = '';
  public selectedThreadVoiceNote = '';
  public showImageShareOptions = false;
  public showVideoShareOptions = false;
  public showVoiceNoteShareOptions = false;
  public mediaEmailShareOptions: any = { email: '', subject: '', message: '', media_url: '' };
  private previousFilterMechanic: number;
  private notificationSound: any;
  bsModalRefCustomer: BsModalRef;
  bsModalRefTicketsLocation: BsModalRef;
  public assignedMechanicId: number;
  public mediaRecorder: any = false;
  public showBillingForm = false;
  public frmBilling: FormGroup;
  public datePickerConfig = {
    isAnimated: true,
    dateInputFormat: 'MM-DD-YYYY',
    showWeekNumbers: false
  };
	public cardCustomErrorMsg = '';
  public validationMessages: any = {
    payment_status: {
    },
    time_spent: {
    },
    billing_method: {
    },
    billing_price: {
    },
    billing_card_number: {
    },
    billing_card_expiry: {
    },
		billing_card_cvc: {
			maxLength: 'Invalid CVC.'
    },
    billing_snapshot: {
      allowedType: 'Invalid file type. Please select jpeg/jpg/png/gif files only.'
    }
  };

	public recording = false;
	public recordRTC: any;

  @HostListener('window:resize') onWindowResize() {
    this.resizeContainers();
  }

  constructor(
    private route: ActivatedRoute,
    public helper: IdeateHelper,
    public account: Account,
    private customValidators: IdeateValidators,
    private modalService: BsModalService,
    private localeService: BsLocaleService,
    private pushNotifications: PushNotificationsService,
    public app: App,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef
  ) {
    window['dashboard'] = this;
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.viewTicketID = (params.get('view_ticket_id')) ? +params.get('view_ticket_id') : 0;
      this.inquiryID = (params.get('inquiry_id')) ? +params.get('inquiry_id') : 0;
      if (this.inquiryID > 0) {
        this.openTicketEditModal('inquiry');
      }
    });

    this.notificationSound = new Audio('assets/sounds/notification.mp3');

    this.initFilterValues();
    this.dtColsCount = this.dtElementRef.nativeElement.children[0].children[0].children.length;
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.initDataTable();
    });

    this.filterInputsChangedSub
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(model => {

        /* On change value of the keyword input,
        If the value is not empty, disable the date filters.
        If it's empty enable the date filters. */
        if (model) {
          this.areDateFiltersEnabled = false;
        } else {
          this.areDateFiltersEnabled = true;
        }
        this.onTicketsFilterUpdated();
      });

    this.localeService.use('en');

    this.initForm();
    this.getServices();
    this.getBillingManagers();

    this.threadVideoModal.onShown.subscribe(() => {
      const video = this.elementRef.nativeElement.querySelector('video');
      video.load();
    });

    this.threadVideoModal.onHidden.subscribe(result => {
      this.showVideoShareOptions = false;
      const video = this.elementRef.nativeElement.querySelector('video');
      video.pause();
    });

    this.threadImageModal.onHidden.subscribe(result => {
      this.showImageShareOptions = false;
    });
  }

  initForm() {
    this.formCustomerSelection = this.formBuilder.group({
      customer: this.selectedTicket.customer
    });
  }

  initBillingForm() {
    this.showBillingForm = false;

    if (this.frmBilling) {
      this.frmBilling.reset();
    }

    this.frmBilling = this.formBuilder.group({
      payment_status: [(this.selectedTicket.payment_status && this.selectedTicket.payment_status !== '') ? this.selectedTicket.payment_status : 'unpaid'],
      time_spent: [this.createDateObjectFromSeconds(this.helper.getObjVal(this.selectedTicket, ['time_spent'], 0))],
      billing_method: [this.selectedTicket.billing_method],
      billing_price: [this.selectedTicket.billing_price],
      billing_card_number: [this.selectedTicket.billing_card_number],
      billing_card_expiry: [this.selectedTicket.billing_card_expiry],
      billing_card_cvc: [this.selectedTicket.billing_card_cvc,
				this.selectedTicket.billing_method === 'card' ?
				Validators.compose([Validators.maxLength(4), Validators.minLength(3), Validators.required]) : 
				Validators.nullValidator],
      billing_snapshot: ['', Validators.compose([this.customValidators.allowedType(['image/jpeg', 'image/jpg', 'image/png', 'image/gif'])])]
    });

    this.frmBilling.statusChanges.subscribe(() => {
      this.validateBillingForm();
    });

		// Add a listener for changes to 'billing_method' control
		this.frmBilling.get('billing_method').valueChanges.subscribe((billingMethod) => {
			const cvcControl = this.frmBilling.get('billing_card_cvc');
			if (billingMethod === 'card') {
				// If billing_method is 'new_card', add the validation for cvc
				cvcControl.setValidators([Validators.maxLength(4), Validators.minLength(3), Validators.required]);
			} else {
				// If it's a saved card, remove the validation for cvc
				this.cardCustomErrorMsg = '';
				cvcControl.setValidators(Validators.nullValidator);
			}
			// Update the validity of the cvc control
			cvcControl.updateValueAndValidity();
		});

    this.validateBillingForm();
  }

  validateBillingForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmBilling, this.validationMessages);
  }

  saveBilling(timeOutInterval: number = 0) {
    setTimeout(() => {
      if (this.frmBilling.valid && this.selectedTicket && this.selectedTicket.id > 0) {

				if(this.selectedTicket.billing_txn_id !== ''){
					return false;
				}

        const userInputs: any = this.frmBilling.value;
        const reqParams: any = {};
        reqParams.user_id = this.account.info.id;
        reqParams.auth_token = this.account.info.auth_token;
        reqParams.record_id = this.selectedTicket.id;

        reqParams.payment_status = userInputs.payment_status;
        reqParams.time_spent = userInputs.time_spent === '' ? 0 : this.convertDateObjectToSeconds(userInputs.time_spent);
        reqParams.billing_method = userInputs.billing_method;
        reqParams.billing_price = (userInputs.billing_price) ? userInputs.billing_price.toString().replace(/[^\d.-]/g, '') : 0;
        reqParams.billing_card_number = userInputs.billing_card_number;
        reqParams.billing_card_expiry = userInputs.billing_card_expiry;
        reqParams.billing_card_cvc = userInputs.billing_card_cvc;
        if (userInputs.billing_snapshot && userInputs.billing_snapshot.length > 0) {
          reqParams.billing_snapshot = userInputs.billing_snapshot[0];
        }

        this.helper.makeAPIRequest('tickets/save_billing_info', reqParams).then((response) => {
          if (response.success === 1) {
            this.handleTicketObject(response.data.ticket);
            this.checkUnhandledTickets();
            this.showBillingForm = false;
						if(userInputs.billing_method !== 'check' && userInputs.billing_method !== 'cash'){
							this.helper.showNotification('success', 'Payment done successfully!', this.helper.config.defaultSuccessTitle);
						}else{
							this.helper.showNotification('success', 'Billing info saved!', this.helper.config.defaultSuccessTitle);
						}
          } else if (response.error === 1) {
            if (response.errorCode === 2) {
              this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
              this.account.logOut();
            } else if ([5, 8, 10, 4, 6].includes(response.errorCode)) {
							this.helper.showNotification('danger', response.message, '');
						} else {
              this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          } else {
            this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        }).catch((httpError) => {
          this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
        });
      }
    }, timeOutInterval);
  }

  getServices() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    this.helper.makeAPIRequest('services/lookup', reqParams).then(result => {
      if (result.success === 1) {
        this.services = result.data.record;
      }
    });
  }

  getBillingManagers() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.billing_managers_only = 1;
    this.helper.makeAPIRequest('customers/lookup', reqParams).then(result => {
      if (result.success === 1) {
        this.billingManagers = result.data.record;
      }
    });
  }

  syncMechanics(mechanics: any) {
    const mechanicsPrevData = this.mechanics;
    this.mechanics = mechanics;

    if (mechanicsPrevData && mechanicsPrevData.length > 0) {
      for (const mechanic of this.mechanics) {
        const mechanicsPrevDataIdx = this.helper.getArrayIndex(mechanicsPrevData, 'id', mechanic.id);
        const mechanicAvailabilityBefore = (mechanicsPrevDataIdx === false || mechanicsPrevData[mechanicsPrevDataIdx].hide_from_mechanic_status_bar === 1 || mechanicsPrevData[mechanicsPrevDataIdx].is_available === 0 || mechanicsPrevData[mechanicsPrevDataIdx].tickets.length > 0) ? false : true;
        const mechanicAvailabilityNow = (mechanic.hide_from_mechanic_status_bar === 1 || mechanic.is_available === 0 || mechanic.tickets.length > 0) ? false : true;
        if (mechanicAvailabilityBefore === false && mechanicAvailabilityNow === true) {
          if (this.notificationSound) {
            this.notificationSound.play();
          }
        }
      }
    }
  }

  toggleMechanicAvailability(mechanicID: number) {
    const mechanicIdx = this.helper.getArrayIndex(this.mechanics, 'id', mechanicID);
    if (mechanicIdx !== false) {
      this.mechanics[mechanicIdx].is_available = (this.mechanics[mechanicIdx].is_available === 0) ? 1 : 0;

      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.record_id = this.mechanics[mechanicIdx].id;
      reqParams.is_available = this.mechanics[mechanicIdx].is_available;
      this.helper.makeAPIRequest('users/update_mechanics_availability', reqParams).then((response) => {
        if (response.success === 1) {
          // Resetting the mechanic's availability if the mechanic list is updated.
          const mechanicIdx1 = this.helper.getArrayIndex(this.mechanics, 'id', reqParams.record_id);
          if (mechanicIdx1 !== false) {
            this.mechanics[mechanicIdx1].is_available = reqParams.is_available;
          }
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
        this.ticketNote = '';
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
        this.ticketNote = '';
      });
    }
  }

  initFilterValues() {
    if (this.app.ticketFilters.default === 1) {
      this.filterKeyword = '';
      this.filterMechanic = undefined;

      const days = 7; // Numbers of days prior to the current date that will be set as default for filterDateStart
      const date = new Date();
      const defaultFilterDateStart = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);

      this.filterDateStart = defaultFilterDateStart;
      this.filterDateEnd = '';
      this.filterStatusesAll = false;
      this.filterShowScheduledTickets = false;
      this.areDateFiltersEnabled = true;
      this.filterStatuses = this.app.ticketStatusTypes;
      for (let i = 0; i < this.filterStatuses.length; i++) {
        if (['open', 'assigned', 'confirmed', 'started'].filter((item) => item === this.filterStatuses[i].value).length > 0) {
          this.filterStatuses[i].isSelected = true;
        } else {
          this.filterStatuses[i].isSelected = false;
        }
      }
    } else {
      this.filterKeyword = this.app.ticketFilters.filterKeyword;
      this.filterMechanic = this.app.ticketFilters.filterMechanic;
      this.filterDateStart = this.app.ticketFilters.filterDateStart;
      this.filterDateEnd = this.app.ticketFilters.filterDateEnd;
      this.filterStatusesAll = this.app.ticketFilters.filterStatusesAll;
      this.filterShowScheduledTickets = this.app.ticketFilters.filterShowScheduledTickets;
      this.areDateFiltersEnabled = this.app.ticketFilters.areDateFiltersEnabled;
      this.filterStatuses = this.app.ticketFilters.filterStatuses;
    }
  }

  resetFilters() {
    this.app.ticketFilters.default = 1;
    this.initFilterValues();
    this.onTicketsFilterUpdated();
  }

  evFilterInputsChanged(query: any) {
    this.filterInputsChangedSub.next(query);
  }

  onMechanicChanged(): void {
    if (this.previousFilterMechanic === this.filterMechanic) {
      return;
    }

    this.onTicketsFilterUpdated();
    this.previousFilterMechanic = this.filterMechanic;
  }

  onTicketsFilterAllStatusUpdated() {
    for (let i = 0; i < this.filterStatuses.length; i++) {
      this.filterStatuses[i].isSelected = this.filterStatusesAll;
    }
    this.onTicketsFilterUpdated();
  }

  onTicketsFilterUpdated() {
    this.app.ticketFilters.default = 0;
    this.app.ticketFilters.filterKeyword = this.filterKeyword;
    this.app.ticketFilters.filterMechanic = this.filterMechanic;
    this.app.ticketFilters.filterDateStart = this.filterDateStart;
    this.app.ticketFilters.filterDateEnd = this.filterDateEnd;
    this.app.ticketFilters.filterStatusesAll = this.filterStatusesAll;
    this.app.ticketFilters.filterShowScheduledTickets = this.filterShowScheduledTickets;
    this.app.ticketFilters.areDateFiltersEnabled = this.areDateFiltersEnabled;
    this.app.ticketFilters.filterStatuses = this.filterStatuses;
    this.initDataTable(500);
  }

  handleTicketObject(ticket: any, showNotification: boolean = false) {

    if (showNotification && ticket.last_thread_created_by !== this.account.info.id && ticket.last_thread_created_at_ts > this.dtRowsLastUpdatedTS) {

      const threadCreatorName = ticket.last_thread_created_by_fname + ' ' + ticket.last_thread_created_by_lname;
      let isImportantNotification = false;
      let notificationTitle = '';
      let notificationBody = '';

      if ((ticket.last_thread_previous_status !== 'completed' && ticket.status === 'completed') || (ticket.last_thread_note !== '')) {
        isImportantNotification = true;
      }

      notificationTitle = 'Ticket updated!';
      if (ticket.last_thread_previous_status !== 'completed' && ticket.status === 'completed') {
        notificationBody += threadCreatorName + ' completed the job.';
      } else {
        notificationBody += threadCreatorName + ' has updated the ticket.';
      }
      if (ticket.last_thread_ticket_updates !== '') {
        notificationBody += '\n' + this.helper.ucWords(ticket.last_thread_ticket_updates.replace(/,/g, ', ').replace(/_/g, ' ')) + ' updated.';
        if (ticket.last_thread_previous_status !== ticket.last_thread_status) {
          notificationBody += '\nTicket status changed to ' + ticket.last_thread_status + '.';
        }
        if (ticket.last_thread_note !== '') {
          notificationBody += '\nMessage: ' + ticket.last_thread_note + '.';
        }
      }

      if (notificationBody !== '') {
        if (isImportantNotification) {
          this.notificationSound.play();
          this.pushNotifications.create(notificationTitle, { body: notificationBody }).subscribe(
            (pushNotification: any) => {
              if (pushNotification.event.type === 'click') {
                window.focus();
                this.helper.showNotification('success', notificationBody.replace(/\n/g, '<br />'), notificationTitle);
                pushNotification.notification.close();
              }
            },
            (err: any) => {
              alert(notificationTitle + '\n' + notificationBody);
            }
          );
        } else {
          this.helper.showNotification('success', notificationBody.replace(/\n/g, '<br />'), notificationTitle);
        }
      }

    }

    let filterValidated = true;
    if (filterValidated && this.filterMechanic && (!ticket.mechanic || +this.filterMechanic !== +ticket.mechanic.id)) {
      filterValidated = false;
    }
    const currDateTS: any = new Date().setHours(0, 0, 0, 0);
    const serviceDate: any = (ticket.service_date) ? this.helper.dtFormatFromStrToObj(ticket.service_date) : ticket.service_date;
    const completedDate: any = (ticket.completed_date) ? this.helper.dtFormatFromStrToObj(ticket.completed_date) : ticket.completed_date;
    const cancelledDate: any = (ticket.cancelled_date) ? this.helper.dtFormatFromStrToObj(ticket.cancelled_date) : ticket.cancelled_date;
    if (
      filterValidated && this.filterDateStart && (
        (ticket.status != 'completed' && ticket.status != 'cancelled' && this.filterDateStart.setHours(0, 0, 0, 0) > serviceDate.getTime()) ||
        (ticket.status == 'completed' && this.filterDateStart.setHours(0, 0, 0, 0) > completedDate.getTime()) ||
        (ticket.status == 'cancelled' && this.filterDateStart.setHours(0, 0, 0, 0) > cancelledDate.getTime())
      )
    ) {
      filterValidated = false;
      /* if (this.filterDateEnd) {
        filterValidated = false;
      } else if (['cancelled', 'completed'].filter((item: any) => (item === ticket.status)).length >= 1) {
        filterValidated = false;
      } */
    }
    if (
      filterValidated && this.filterDateEnd && (
        (ticket.status != 'completed' && ticket.status != 'cancelled' && this.filterDateEnd.setHours(0, 0, 0, 0) < serviceDate.getTime()) ||
        (ticket.status == 'completed' && this.filterDateEnd.setHours(0, 0, 0, 0) < completedDate.getTime()) ||
        (ticket.status == 'cancelled' && this.filterDateStart.setHours(0, 0, 0, 0) < cancelledDate.getTime())
      )
    ) {
      filterValidated = false;
    }
    if (filterValidated && !this.filterShowScheduledTickets && serviceDate && serviceDate.getTime() > currDateTS) {
      filterValidated = false;
    }
    if (filterValidated && this.filterStatuses.filter((item) => item.isSelected && item.value === ticket.status).length === 0) {
      filterValidated = false;
    }

    const dtRowIdx = this.helper.getArrayIndex(this.dtRows, 'id', ticket.id);
    let queueAction = '';
    if (filterValidated) {
      const dtRow = {
        is_scheduled: ticket.is_scheduled,
        is_pinned: ticket.is_pinned,
        is_selected: ticket.is_selected,
        id: ticket.id,
        services: ticket.services,
        service_date: ticket.service_date,
        service_time_from: ticket.service_time_from,
        service_time_to: ticket.service_time_to,
        status: ticket.status,
        latitude: ticket.latitude,
        longitude: ticket.longitude,
        billing_status: ticket.billing_status,
        payment_status: ticket.payment_status,
        created_at_ts: ticket.created_at_ts,
        completed_date: ticket.completed_date,
        completed_at_ts: ticket.completed_at_ts,
        cancelled_date: ticket.cancelled_date,
        cancelled_at_ts: ticket.cancelled_at_ts,
        updated_at_ts: ticket.updated_at_ts,
        note: ticket.note,
        customer: ticket.customer,
        mechanic: ticket.mechanic,
        short_address: ticket.short_address
      };
      if (dtRowIdx !== false) {
        dtRow.is_selected = this.dtRows[dtRowIdx].is_selected;
        this.dtRows[dtRowIdx] = dtRow;
        queueAction = 'updated';
      } else {
        this.dtRows.unshift(dtRow);
        queueAction = 'added';
      }
    } else {
      if (dtRowIdx !== false) {
        this.dtRows.splice(dtRowIdx, 1);
        queueAction = 'deleted';
      }
    }

    const tempTicketIdx = this.helper.getArrayIndex(this.dtRows, 'id', ticket.id);
    if (tempTicketIdx !== false && (queueAction === 'added' || queueAction === 'updated')) {
      const tempTicket = this.dtRows[tempTicketIdx];
      this.dtRows.splice(tempTicketIdx, 1);
      const cntPinnedTickets = this.dtRows.filter((item: any) => (item.is_pinned === 1)).length;
      const unpinnedTickets = this.dtRows.filter((item: any) => (item.is_pinned === 0));
      let newIndex = -1;
      if (tempTicket.is_pinned === 1) {
        newIndex = 0;
      } else {
        const dtRowIdxByStatus = [];
        dtRowIdxByStatus.push({ status: 'open', index: this.helper.getArrayIndex(unpinnedTickets, 'status', 'open') });
        dtRowIdxByStatus.push({ status: 'assigned', index: this.helper.getArrayIndex(unpinnedTickets, 'status', 'assigned') });
        dtRowIdxByStatus.push({ status: 'confirmed', index: this.helper.getArrayIndex(unpinnedTickets, 'status', 'confirmed') });
        dtRowIdxByStatus.push({ status: 'started', index: this.helper.getArrayIndex(unpinnedTickets, 'status', 'started') });
        dtRowIdxByStatus.push({ status: 'completed', index: this.helper.getArrayIndex(unpinnedTickets, 'status', 'completed') });
        dtRowIdxByStatus.push({ status: 'cancelled', index: this.helper.getArrayIndex(unpinnedTickets, 'status', 'cancelled') });
        let flagAssignNewIdx = false;
        for (let i = 0; i < dtRowIdxByStatus.length; i++) {
          if (dtRowIdxByStatus[i].status === tempTicket.status) {
            flagAssignNewIdx = true;
          }
          if (flagAssignNewIdx && newIndex < 0 && dtRowIdxByStatus[i].index !== false) {
            newIndex = dtRowIdxByStatus[i].index + cntPinnedTickets;
          }
        }
      }
      if (newIndex < 0) {
        newIndex = (this.dtRows.length > 0) ? this.dtRows.length : 0;
      }
      this.dtRows.splice(newIndex, 0, tempTicket);
    }

    if (this.selectedTicket.id === ticket.id) {
      if (queueAction === 'added' || queueAction === 'updated') {
        if (ticket.thread) {
          this.selectedTicket = ticket;
          this.initBillingForm();
          this.resizeContainers();
        } else {
          this.getTicketDetailsById(ticket.id);
        }
      } else if (queueAction === 'deleted') {
        this.selectedTicket = {};
        if (this.dtRows.length > 0) {
          this.getTicketDetailsById(this.dtRows[0].id);
        }
      }
    }
  }

  initDataTable(timeOutInterval: number = 0) {
    this.stopTicketsSyncing();
    if (this.dtInitTimeout) {
      clearTimeout(this.dtInitTimeout);
    }
    this.dtInitTimeout = setTimeout(() => {
      this.dtRowsLastUpdatedTS = 0;
      this.dtRows = [];
      this.loadDatable();
    }, timeOutInterval);
  }

  loadDatable() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.order = '';
    reqParams.last_updated_at_ts = this.dtRowsLastUpdatedTS;
    reqParams.filter_keyword = this.filterKeyword;
    if (this.areDateFiltersEnabled) {
      reqParams.filter_start_date = this.helper.dFormatToDB(this.filterDateStart);
      reqParams.filter_end_date = this.helper.dFormatToDB(this.filterDateEnd);
    }

    reqParams.filter_show_scheduled_tickets = +this.filterShowScheduledTickets;
    reqParams.filter_mechanic = this.filterMechanic ? this.filterMechanic : '';
    reqParams.filter_status = this.filterStatuses.filter((item: any) => item.isSelected).map((item: any) => item.value).join(',');

    this.ticketsInfoListMessage = 'Loading tickets...';

    this.selectedTicket = {};
    this.dtOptions = {
      pagingType: 'simple',
      lengthMenu: [[50, 100, 200, 500, 1000, -1], [50, 100, 200, 500, 1000, 'All']],
      pageLength: -1,
      serverSide: true,
      processing: false,
      searching: false,
      paging: false,
      dom: 'flrtp',
      ajax: (dtRequestParameters: any, callback) => {
        /* dtRequestParameters.async = false; */
        this.dtRequestParameters = Object.assign(dtRequestParameters, reqParams);
        this.helper.makeAPIRequest('tickets/list', this.dtRequestParameters).then((response) => {
          if (response.data.mechanics) {
            this.syncMechanics(response.data.mechanics);
          }
          if (response.success === 1) {
            this.dtRows = response.data.datatable.rows;
            this.dtRowsLastUpdatedTS = response.data.last_updated_at_ts;
            this.startTicketsSyncing();
            if (this.dtRows.length > 0) {
              if (this.viewTicketID > 0) {
                this.getTicketDetailsById(this.viewTicketID);
              } else {
                this.getTicketDetailsById(this.dtRows[0].id);
              }
            }
            this.checkUnhandledTickets();
            callback({
              recordsTotal: response.data.datatable.recordsTotal,
              recordsFiltered: response.data.datatable.recordsFiltered,
              data: []
            });
          } else if (response.error === 1) {
            if (response.errorCode === 2) {
              this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
              if (this.bsModalRefCustomer) {
                this.bsModalRefCustomer.hide();
              }
              if (this.bsModalRefTicketsLocation) {
                this.bsModalRefTicketsLocation.hide();
              }
              this.account.logOut();
            } else if (response.errorCode === 4) {
              this.ticketsInfoListMessage = this.helper.config.defaultNoResultErrorMsg;
              // No records found.
            } else {
              if (this.bsModalRefCustomer) {
                this.bsModalRefCustomer.hide();
              }
              if (this.bsModalRefTicketsLocation) {
                this.bsModalRefTicketsLocation.hide();
              }
              this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          } else {
            this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        }).catch((httpError) => {
          console.log('connectivity error: ' + httpError);
          this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
        });
      }
    };
    setTimeout(() => {
      if (this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.dtTrigger.next();
      }
      /* this.dtElement.dtInstance.then((dtInstance) => { setInterval(() => { dtInstance.ajax.reload(); }, 30000); }); */
    });
  }

  startTicketsSyncing() {
    this.stopTicketsSyncing();
    this.syncTicketsInterval = setInterval(() => {
      this.syncTickets();
    }, 10000);
  }

  stopTicketsSyncing() {
    if (this.syncTicketsInterval) {
      clearInterval(this.syncTicketsInterval);
    }
  }

  syncTickets() {
    if (this.dtRowsLastUpdatedTS > 0) {
      this.dtRequestParameters.last_updated_at_ts = this.dtRowsLastUpdatedTS;
      this.helper.makeAPIRequest('tickets/list', this.dtRequestParameters).then((response) => {

        if (response.data.mechanics) {
          this.syncMechanics(response.data.mechanics);
        }
        if (response.success === 1) {
          if (response.data.datatable.rows.length > 0) {
            response.data.datatable.rows.forEach((ticket: any) => {
              this.handleTicketObject(ticket, true);
            });
            this.checkUnhandledTickets();
            this.dtRowsLastUpdatedTS = response.data.last_updated_at_ts;
          }
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            if (this.bsModalRefCustomer) {
              this.bsModalRefCustomer.hide();
            }
            if (this.bsModalRefTicketsLocation) {
              this.bsModalRefTicketsLocation.hide();
            }
            this.account.logOut();
          } else if (response.errorCode === 4) {
            // No records found.
          } else {
            if (this.bsModalRefCustomer) {
              this.bsModalRefCustomer.hide();
            }
            if (this.bsModalRefTicketsLocation) {
              this.bsModalRefTicketsLocation.hide();
            }
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
        console.log('connectivity error: ' + httpError);
      });
    }
  }

  public resizeContainers() {
    if (window.innerWidth > 768) {
      setTimeout(() => {
        const commonOffset = 35;
        const header = <HTMLElement>document.querySelector('pg-header .header');
        const headerHeight = (header) ? header.offsetHeight : 0;
        const mechanicsListHeight = this.mechanicsList.nativeElement.offsetHeight;
        const customerFiltersHeight = this.customerFilters.nativeElement.offsetHeight;
        const customerFiltersMechanicsListHeight = (customerFiltersHeight > mechanicsListHeight) ? customerFiltersHeight : mechanicsListHeight;

        const customersListHeadingHeight = this.customersListHeading.nativeElement.offsetHeight;
        const dtHeadHeight = this.dtHeadElementRef.nativeElement.offsetHeight;
        const dtBodyHeight = window.innerHeight - headerHeight - customerFiltersMechanicsListHeight - customersListHeadingHeight - dtHeadHeight - commonOffset;
        this.dtBodyElementRef.nativeElement.style.height = dtBodyHeight + 'px';

        if (this.ticketDetailsHeading) {
          this.ticketDetails.nativeElement.style.height = this.customersListingContainer.nativeElement.offsetHeight + 'px';
          const ticketDetailsOffset = 35;
          const ticketDetailsHeadingHeight = this.ticketDetailsHeading.nativeElement.offsetHeight;
          const ticketDetailsServiceInfoHeight = this.ticketDetailsServiceInfo.nativeElement.offsetHeight;
          const ticketDetailsActionAndBillingsHeight = this.ticketDetailsActionAndBillings.nativeElement.offsetHeight;
          const ticketDetailsThreadReplyHeight = this.ticketDetailsThreadReply.nativeElement.offsetHeight;
          const ticketDetailsThreadHistoryHeight = window.innerHeight - headerHeight - customerFiltersMechanicsListHeight - ticketDetailsHeadingHeight - ticketDetailsServiceInfoHeight - ticketDetailsActionAndBillingsHeight - ticketDetailsThreadReplyHeight - commonOffset - ticketDetailsOffset;
          this.ticketDetailsThreadHistory.nativeElement.style.height = ticketDetailsThreadHistoryHeight + 'px';
          this.ticketDetailsThreadHistory.nativeElement.scrollTop = this.ticketDetailsThreadHistory.nativeElement.scrollHeight;
        }

      });
    }
  }

  public async openTicketEditModal(mode: string) {
    while (true) {
      if (this.services && this.mechanics && this.billingManagers) {
        const initialState = {
          record: (mode == 'edit') ? this.selectedTicket : {},
          inquiryID: (mode == 'inquiry') ? this.inquiryID : 0,
          services: this.services,
          mechanics: this.mechanics,
          billingManagers: this.billingManagers,
        };
        this.bsModalRefCustomer = this.modalService.show(TicketsAddEditComponent, { initialState, class: 'modal-lg tickets-add-edit-modal', ignoreBackdropClick: true });
        this.bsModalRefCustomer.content.onClose.subscribe((ticket: any) => {
          if (ticket !== false) {
            this.handleTicketObject(ticket);
            this.checkUnhandledTickets();
          }
        });
        break;
      } else {
        await new Promise(f => setTimeout(f, 1000));
      }
    }
  }

  public async startRecording() {

		if(!this.recording){
			navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
				this.recordRTC = RecordRTC(stream, {
					type: 'audio',
					mimeType: 'audio',
					recorderType: RecordRTC.StereoAudioRecorder
				});
				this.recording = true;
				this.recordRTC.startRecording();
			}).catch((error) => {
				console.error('Error accessing microphone:', error);
			});
		}

    /* if ('MediaRecorder' in window) {
      // everything is good, let's go ahead
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        });        
        const mimeType = 'audio/webm;codecs=opus';
        let chunks = [];
        this.mediaRecorder = new MediaRecorder(stream, { mimeType: mimeType });
        this.mediaRecorder.addEventListener('dataavailable', event => {
          if (typeof event.data === 'undefined') return;
          if (event.data.size === 0) return;
          chunks.push(event.data);
        });
        this.mediaRecorder.addEventListener('stop', () => {
          const blobVoiceNote = new Blob(chunks, {
            type: mimeType
          });
          let container = new DataTransfer();
          let file = new File([blobVoiceNote], "voice_note.webm", { type: mimeType, lastModified: new Date().getTime() });
          container.items.add(file);
          this.noteThreadMedia.nativeElement.value = '';
          this.noteThreadMedia.nativeElement.files = container.files;
          chunks = [];
        });
        this.mediaRecorder.start();
      } catch (e) {
        console.log(e);
        this.helper.showNotification('danger', "Something went wrong! Try these steps to fix the issue: <br/>1) Make sure you didn't denied the permission prompt presented while accessing the recorder.<br/>2) Make sure you're accessing this site from https (secured) protocol.<br/>3) If you're running on iOS, Make sure your device is up to date and try enabling the 'Media Recorder', 'Modern WebAudio API', 'NSURLSession WebSocket', 'RedableByteStream', 'WebRTC... (All items)', 'WritableStreamAPI' from Settings > Safari > Advanced > Experimental Features", this.helper.config.defaultErrorTitle);
      }
    } else {
      this.helper.showNotification('danger', "Your browser doesn't support this Feature.", this.helper.config.defaultAuthErrorTitle);
    } */
  }

  public async stopRecording() {

		if(this.recording){
			this.recording = false;
			this.recordRTC.stopRecording(() => {
				const blob = this.recordRTC.getBlob();
				let container = new DataTransfer();
				let file = new File([blob], "voice_note.wav", { type: 'audio/wav', lastModified: new Date().getTime() });
				container.items.add(file);
				this.noteThreadMedia.nativeElement.value = '';
				this.noteThreadMedia.nativeElement.files = container.files;
			});
		}

    /* this.mediaRecorder.stop();
    setTimeout(() => {
      this.mediaRecorder == false;
    }) */
  }

  async takeActionOnTicket(ticketId: number, note: string = '', mediaFiles: any = undefined, isImportantNote: number = 0, isCustomerNote: number = 0, notifyCustomer: number = 0, status: string = '', mechanic: number = 0, modal: ModalDirective) {
    if (modal) {
      modal.hide();
    }

    let mediaFileBase64: any = '';
    if (mediaFiles && mediaFiles.length == 1) {
      mediaFileBase64 = await this.helper.readFile(mediaFiles[0]);
    }

    if (ticketId > 0) {
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.record_id = ticketId;
      reqParams.mechanic = mechanic;
      reqParams.status = status;
      reqParams.note = note;
      reqParams.media_file = mediaFileBase64;
      reqParams.is_important_note = isImportantNote;
      reqParams.is_customer_note = isCustomerNote;
      reqParams.notify_customer = notifyCustomer;
      this.helper.makeAPIRequest('tickets/respond', reqParams, 'post', true).then((response) => {
        if (response.success === 1) {
          this.handleTicketObject(response.data.record);
          this.checkUnhandledTickets();
          this.helper.showNotification('success', 'Ticket ' + (status ? status : 'thread saved') + ' successfully!', this.helper.config.defaultSuccessTitle);
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else if (response.errorCode === 4) {
            this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
        this.ticketNote = '';
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
        this.ticketNote = '';
      });
      return true;
    }
    return false;
  }

  showThreadMediaShareViaEmailModal(mediaURL: string) {
    if (mediaURL) {
      this.mediaEmailShareOptions.email = (this.selectedTicket && this.selectedTicket.customer && this.selectedTicket.customer.email) ? this.selectedTicket.customer.email : '';
      this.mediaEmailShareOptions.subject = '';
      this.mediaEmailShareOptions.message = '';
      this.mediaEmailShareOptions.media_url = mediaURL;
      this.threadMediaShareViaEmailModal.show();
    } else {
      this.helper.showNotification('danger', this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
    }
  }

  shareThreadMediaViaEmail() {
    if (this.mediaEmailShareOptions.email !== '' && this.mediaEmailShareOptions.media_url !== '') {
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.media_url = this.mediaEmailShareOptions.media_url;
      reqParams.email_id = this.mediaEmailShareOptions.email;
      reqParams.subject = this.mediaEmailShareOptions.subject;
      reqParams.message = this.mediaEmailShareOptions.message;
      this.helper.makeAPIRequest('tickets/share_media_via_email', reqParams).then((response) => {
        if (response.success === 1) {
          this.threadMediaShareViaEmailModal.hide();
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }
  }

  getCustomersTicketHistory(customerId: number) {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.record_id = customerId;
    this.helper.makeAPIRequest('customers/tickets_history', reqParams).then((response) => {
      if (response.success === 1) {
        this.customersTicketHistory = response.data.tickets;
        this.customerTicketHistoryModal.show();
      } else if (response.error === 1) {
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else if (response.errorCode === 4) {
          this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
      this.ticketNote = '';
    }).catch((httpError) => {
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      this.ticketNote = '';
    });
  }

  getServiceIds() {
    const selectedServicesIds = [];
    for (const service of this.selectedTicket.services) {
      selectedServicesIds.push(service.id);
    }

    return selectedServicesIds.toString();
  }

  exportTickets() {
    this.helper.makeAPIRequest('tickets/export', this.dtRequestParameters).then((result) => {
      if (result.success === 1) {
        const hiddenElement = document.createElement('a');

        hiddenElement.href = result.data.path;
        hiddenElement.click();
      } else if (result.error === 1) {
        if (result.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else if (result.errorCode === 3) {
          this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
        } else if (result.errorCode === 4) {
          this.helper.showNotification('danger', 'Failed to generate the CSV.', this.helper.config.defaultErrorTitle);
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + result.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }

  exportTicketsCustomerHistory() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.filter_show_scheduled_tickets = 0;
    reqParams.filter_customer = this.selectedTicket.customer.id;
    reqParams.filter_status = this.filterStatuses.map((item: any) => item.value).join(',');
    this.helper.makeAPIRequest('tickets/export', reqParams).then((result) => {
      if (result.success === 1) {
        const hiddenElement = document.createElement('a');

        hiddenElement.href = result.data.path;
        hiddenElement.click();
      } else if (result.error === 1) {
        if (result.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else if (result.errorCode === 3) {
          this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
        } else if (result.errorCode === 4) {
          this.helper.showNotification('danger', 'Failed to generate the CSV.', this.helper.config.defaultErrorTitle);
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + result.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }

  viewTicketsOnMap() {
    const initialState = {
      places: []
    };
    this.mechanics.forEach(item => {
      if (item.hide_from_mechanic_status_bar === 0 && item.is_available === 1) {
        if(!this.helper.isEmptyObj(item.gps_location)){
          const place: any = { id: item.id, location: { latitude: item.gps_location.latitude, longitude: item.gps_location.longitude }, infoPopupContent: '', markerIcon: 'assets/images/markers/person-blue.png', infoPopupOpened: false };
          // place.infoPopupContent += '<div><i class="fa fa-map-marker"></i> ' + item.last_ticket_in_action.short_address + '</div>';
          if(item.fname != null && item.lname != null){
            place.infoPopupContent += '<div><i class="fa fa-user-secret"></i> ' + item.fname + ' ' + item.lname + '</div>';
          }
          initialState.places.push(place);
        }else if(+this.helper.getObjVal(item.last_ticket_in_action, ['id'], '0') !== 0){
          const place: any = { id: item.last_ticket_in_action.id, location: { latitude: item.last_ticket_in_action.latitude, longitude: item.last_ticket_in_action.longitude }, infoPopupContent: '', markerIcon: 'assets/images/markers/person-blue.png', infoPopupOpened: false };
          place.infoPopupContent += '<div><i class="fa fa-map-marker"></i> ' + item.last_ticket_in_action.short_address + '</div>';
          if(item.last_ticket_in_action.mechanic.fname != null && item.last_ticket_in_action.mechanic.lname != null){
            place.infoPopupContent += '<div><i class="fa fa-user-secret"></i> ' + item.last_ticket_in_action.mechanic.fname + ' ' + item.last_ticket_in_action.mechanic.lname + '</div>';
          }
          initialState.places.push(place);
        }
      }
    });
    const cntSelectedTickets = this.dtRows.filter((item1: any) => (item1.is_selected)).length;
    this.dtRows.filter((item1: any) => (cntSelectedTickets === 0 || item1.is_selected)).forEach(item => {
      if (initialState.places.filter((item2: any) => +item2.id === +item.id).length === 0) {
        const ticketStatusColor = this.app.ticketStatusTypes.filter((item2: any) => (item2.value === item.status))[0].color;
        const place: any = { id: item.id, location: { latitude: item.latitude, longitude: item.longitude }, infoPopupContent: '', markerIcon: 'assets/images/markers/circle-' + ticketStatusColor + '.png', infoPopupOpened: false };
        place.infoPopupContent += '<div><i class="fa fa-map-marker"></i> ' + item.short_address + '</div>';
        if(item.mechanic.fname != null && item.mechanic.lname != null){
          place.infoPopupContent += '<div><i class="fa fa-user-secret"></i> ' + item.mechanic.fname + ' ' + item.mechanic.lname + '</div>';
        }
        initialState.places.push(place);
      }
    });
    if (initialState.places.length > 0) {
      this.bsModalRefCustomer = this.modalService.show(MapLocationsComponent, { initialState, class: 'modal-lg modal-map' });
      this.bsModalRefCustomer.content.assignToTicket.subscribe((ticketId) => {
        this.getTicketDetailsById(ticketId, false, true);
      });
    } else {
      this.helper.showNotification('danger', 'There\'s no tickets to be displayed on the Map.', this.helper.config.defaultErrorTitle);
    }
  }

  getTicketDetailsById(ticketId: number, scrollToInfo: boolean = false, showAssignModal: boolean = false) {

    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.record_id = ticketId;
    this.helper.makeAPIRequest('tickets/get', reqParams).then((response) => {
      if (reqParams.record_id === this.viewTicketID) {
        this.viewTicketID = 0;
      }
      if (response.success === 1) {
        this.selectedTicket = response.data.record;
        this.resizeContainers();
        this.initBillingForm();
				// selected default saved card or new card option for the billing method
				if(this.selectedTicket.billing_method === ''){
					if(this.selectedTicket.customer.saved_cards.length > 0 && this.selectedTicket.payment_status === 'unpaid'){
						this.frmBilling.controls.billing_method.setValue(this.selectedTicket.customer.saved_cards[0].id);
					}else{
						if(this.selectedTicket.customer.saved_cards.length === 0){
							this.frmBilling.controls.billing_method.setValue('card');
						}
					}
				}
        if (window.innerWidth <= 768 && scrollToInfo) {
          setTimeout(() => {
            this.ticketDetails.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 100);
        }
        if (showAssignModal) {
          this.assignedMechanicId = this.selectedTicket.mechanic ? this.selectedTicket.mechanic.id : 0;
          this.assignMechanicModal.show();
        }
      } else if (response.error === 1) {
        this.selectedTicket = {};
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else if (response.errorCode === 4) {
          this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
          this.helper.navBack();
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.selectedTicket = {};
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }

  doPinUnpin(ticketId: number, isPinned: number) {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.record_id = ticketId;
    reqParams.is_pinned = (isPinned) ? 0 : 1;
    this.helper.makeAPIRequest('tickets/pin_unpin', reqParams).then((response) => {
      if (response.success === 1) {
        this.handleTicketObject(response.data.ticket);
        this.checkUnhandledTickets();
      } else if (response.error === 1) {
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.selectedTicket = {};
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }

  checkUnhandledTickets() {
    if (this.dtRows.length > 0) {
      for (const ticket of this.dtRows) {
        const ticketServiceDateTS = new Date(this.helper.dtFormatFromStrToObj(ticket.service_date)).getTime();
        const currentDateTS = new Date().setHours(0, 0, 0, 0);

        ticket.isAttentionNeeded = false;
        if (ticketServiceDateTS < currentDateTS && ticket.status === 'open') {
          ticket.isAttentionNeeded = true;
        }
      }
    }
  }

  private convertDateObjectToSeconds(dateObject: Date) {
    return (dateObject.getHours() * 3600) + (dateObject.getMinutes() * 60) + (dateObject.getSeconds());
  }

  private createDateObjectFromSeconds(totalSeconds: number) {
    const hours = totalSeconds / 3600;
    const minutes = (totalSeconds % 3600) / 60;
    const seconds = (totalSeconds % 3600) % 60;

    const dateObject = new Date();
    dateObject.setHours(hours);
    dateObject.setMinutes(minutes);
    dateObject.setSeconds(seconds);
    dateObject.setMilliseconds(0);

    return dateObject;
  }

  downloadFile(mediaType: string, url: string) {
    // window.location.href = url;
    window.open(url, mediaType === 'image' ? '_blank' : '_self');
  }

	cardNumberChange(number){
		// Remove any non-digit characters from the input
		let sanitizedNumber = number.replace(/\D/g, '');
		// Enforce maximum 16 digits limit
		if (sanitizedNumber.length > 16 ) {
			sanitizedNumber = sanitizedNumber.slice(0, 16);
			this.cardCustomErrorMsg = 'Please enter valid card number.';
		} else if(sanitizedNumber.length === 16 || sanitizedNumber.length === 15){
			this.cardCustomErrorMsg = '';
		} else{
			this.cardCustomErrorMsg = 'Please enter valid card number.';
		}

		// Split the input into groups of four digits
		let formattedNumber = sanitizedNumber.replace(/(\d{4})/g, '$1 ');
		// Update the cardNumber property with the formatted value
		number = formattedNumber.trim();
		this.frmBilling.controls.billing_card_number.setValue(number);
	}

	cardExpChange(numberExp){
		// Remove any non-digit characters from the input
		let sanitizedExp = numberExp.replace(/\D/g, '');
		if (sanitizedExp.length > 2) {
			numberExp = sanitizedExp.slice(0, 2) + '/' + sanitizedExp.slice(2, 4);
		}
		if(sanitizedExp.length === 4){
			this.cardCustomErrorMsg = '';
		}else{
			this.cardCustomErrorMsg = 'Please enter valid card expiry.';
		}
		this.frmBilling.controls.billing_card_expiry.setValue(numberExp);
	}

	cardCVCChange(cvc){
		if (cvc.length > 4) {
			this.frmBilling.controls.billing_card_cvc.setValue(cvc.slice(0, 4));
			return false;
		}
	}


  ngOnDestroy(): void {
    this.threadImageModal.hide();
    this.threadVideoModal.hide();
    this.dtTrigger.unsubscribe();
    this.stopTicketsSyncing();
  }
}
