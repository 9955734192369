import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../../core/services/ideate';
import { Account } from '../../providers/account';

import 'rxjs/add/operator/switchMap';


@Component({
  selector: 'app-add-edit-users',
  templateUrl: './add-edit-users.component.html',
  styleUrls: ['./add-edit-users.component.scss']
})
export class AddEditUsersComponent implements OnInit {

  public userType = 'admin';
  public frmAddEdit: FormGroup;
  public recordID = 0;
  public record: any = {};
  public validationMessages: any = {
    fname: {
      required: 'First Name is required.'
    },
    lname: {
    },
    uname: {
      required: 'Username is required.',
      remote: 'Username already exists.',
    },
    email: {
      required: 'Email is required.',
      email: 'Invalid Email.',
      remote: 'Email already exists.',
    },
    password: {
      required: 'Password is required.'
    },
    phone: {
    },
    address: {
    },
    type: {
      required: 'Type is required.'
    },
    commission: {
      min: 'Commission rate must be between 0 to 99',
      max: 'Commission rate must be between 0 to 99'
    },
    gps_imei:{
    },
    hide_from_mechanic_status_bar: {},
    status: {
      required: 'Status is required.'
    }
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public helper: IdeateHelper,
    private customValidators: IdeateValidators,
    private account: Account
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userType = params.get('type') ? params.get('type') : this.userType;
      this.recordID = (params.get('id')) ? +params.get('id') : this.recordID;
      if (this.recordID) {
        this.getRecordData();
      }
    });

    this.initForm();
  }

  getRecordData() {
    if (this.recordID === 0) {
      this.initForm();
    } else {
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.record_id = this.recordID;
      this.helper.makeAPIRequest('users/get', reqParams).then((response) => {
        if (response.success === 1) {
          this.record = response.data.record;
          this.initForm();
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else if (response.errorCode === 4) {
            this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
            this.helper.navBack();
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }
  }

  initForm() {
    this.frmAddEdit = this.formBuilder.group({
      fname: [this.record.fname, Validators.compose([Validators.required])],
      lname: [this.record.lname],
      uname: [this.record.uname, Validators.compose([Validators.required]), this.customValidators.remote('users/check_duplicate', 'field_value', { field_name: 'uname', record_id: this.recordID })],
      email: [this.record.email, Validators.compose([Validators.required, Validators.email]), this.customValidators.remote('users/check_duplicate', 'field_value', { field_name: 'email', record_id: this.recordID })],
      password: [this.record.password, this.recordID ? '' : Validators.compose([Validators.required])],
      phone: [this.record.phone],
      address: [this.record.address],
      type: [this.record.type, Validators.compose([Validators.required])],
      commission: [this.record.commission, Validators.compose([Validators.min(0), Validators.max(99)])],
      gps_imei: [this.record.gps_imei,''],
      hide_from_mechanic_status_bar: [this.record.hide_from_mechanic_status_bar],
      status: [(this.record.status) ? this.record.status : 'active', Validators.compose([Validators.required])]
    });
    this.frmAddEdit.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.frmAddEdit.statusChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmAddEdit, this.validationMessages);
  }

  save(timeOutInterval: number = 0) {
    setTimeout(() => {
      if (this.frmAddEdit.valid) {
        const userInputs: any = this.frmAddEdit.value;
        const reqParams: any = {};
        reqParams.user_id = this.account.info.id;
        reqParams.auth_token = this.account.info.auth_token;
        reqParams.record_id = this.recordID;
        reqParams.fname = userInputs.fname;
        reqParams.lname = userInputs.lname;
        reqParams.uname = userInputs.uname;
        reqParams.email = userInputs.email;
        reqParams.password = userInputs.password;
        reqParams.phone = userInputs.phone;
        reqParams.address = userInputs.address;
        reqParams.type = userInputs.type;
        reqParams.commission = userInputs.commission;
        reqParams.gps_imei = userInputs.gps_imei;
        reqParams.hide_from_mechanic_status_bar = userInputs.hide_from_mechanic_status_bar;
        reqParams.status = userInputs.status;

        this.helper.makeAPIRequest('users/save', reqParams).then((response) => {
          if (response.success === 1) {
            this.helper.showNotification('success', 'User info saved!', this.helper.config.defaultSuccessTitle);
            this.helper.navBack();
          } else if (response.error === 1) {
            if (response.errorCode === 2) {
              this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
              this.account.logOut();
            } else if (response.errorCode === 4) {
              this.helper.showNotification('danger', 'Username already exists.', this.helper.config.defaultErrorTitle);
            } else if (response.errorCode === 5) {
              this.helper.showNotification('danger', 'Email already exists.', this.helper.config.defaultErrorTitle);
            } else {
              this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          } else {
            this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        }).catch((httpError) => {
          this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
        });
      }
    }, timeOutInterval);
  }
}
