import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chat-view',
  templateUrl: './chat-view.component.html',
  styleUrls: ['./chat-view.component.scss']
})
export class ChatViewComponent implements OnInit {

  @Input() messages: any;
  constructor() { }

  ngOnInit() {
  }

}
