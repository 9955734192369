import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../core/services/ideate';
import { Account } from '../providers/account';

import 'rxjs/add/operator/switchMap';
import { GoogleMapSearchComponent } from '../core/components/google-map-search/google-map-search.component';
import { App } from '../providers/app';
import { Observable } from 'rxjs';
import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-edit-customers',
  templateUrl: './add-edit-customers.component.html',
  styleUrls: ['./add-edit-customers.component.scss']
})
export class AddEditCustomersComponent implements OnInit, AfterViewInit {

  @ViewChild(GoogleMapSearchComponent) googleMapSearchComponent: GoogleMapSearchComponent;

	@ViewChild('removeCardConfirmModal') removeCardConfirmModal: ModalDirective;

  public userType = 'admin';
  public frmAddEdit: FormGroup;
  public recordID = 0;
  public record: any = {};
  public validationMessages: any = {
    name: {
      required: 'Name is required.'
    },
    email: {
      email: 'Invalid Email.'
    },
    address: {
      required: 'Address is required.'
    },
    apartment: {
      required: 'Apartment is required.'
    },
    note: {},
    billing_status: {},
    is_billing_manager: {},
    to_be_completed_by_admin: {},
    billing_manager: {}
  };

	public paymentCards = [];
	public savedPaymentCards = [];
	public cardNumberErrorMsg = '';
	public cardExpErrorMsg = '';
	public cardCVCErrorMsg = '';
	public cardRemoveId = 0;

  public billingManagers: any[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public helper: IdeateHelper,
    public app: App,
    private customValidators: IdeateValidators,
    private account: Account
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userType = params.get('type') ? params.get('type') : this.userType;
      this.recordID = (params.get('id')) ? +params.get('id') : this.recordID;
      this.getRecordData();
    });

    this.initForm();
    this.getBillingManagers();
  }

  ngAfterViewInit() {
  }

  getBillingManagers() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.billing_managers_only = 1;
    reqParams.exclude = this.recordID;
    this.helper.makeAPIRequest('customers/lookup', reqParams).then(result => {
      if (result.success === 1) {
        this.billingManagers = result.data.record;
      }
    });
  }

  getRecordData() {
    if (this.recordID === 0) {
      this.initForm();
    } else {
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.record_id = this.recordID;
      this.helper.makeAPIRequest('customers/get', reqParams).then((response) => {
        if (response.success === 1) {
          this.record = response.data.record;
					this.savedPaymentCards = response.data.payment_cards;
          this.record.latitude = +this.record.latitude;
          this.record.longitude = +this.record.longitude;
          this.initForm();
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else if (response.errorCode === 4) {
            this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
            this.helper.navBack();
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }
  }

  initForm() {
    this.frmAddEdit = this.formBuilder.group({
      name: [this.record.name, Validators.compose([Validators.required])],
      email: [this.record.email, Validators.compose([Validators.email])],
      phone: this.formBuilder.array([]),
      address: [this.record.address, Validators.compose([Validators.required])],
      apartment: [this.record.apartment, Validators.compose([Validators.required])],
      note: [this.record.note],
      billing_status: [this.record.billing_status],
      is_billing_manager: [(this.recordID === 0) ? 0 : this.record.is_billing_manager],
      to_be_completed_by_admin: [(this.recordID === 0) ? 0 : this.record.to_be_completed_by_admin],
      billing_manager: [this.helper.getObjVal(this.record, ['billing_manager', 'id'], '')],
      latitude: [this.record.latitude, Validators.compose([Validators.required])],
      longitude: [this.record.longitude, Validators.compose([Validators.required])]
    });
    if (this.record.phone && this.record.phone.length > 0) {
      for (let i = 0; i < this.record.phone.length; i++) {
        const phone = this.record.phone[i];
        this.addPhoneFieldGroup(phone);
      }
    } else {
      this.addPhoneFieldGroup();
    }
    this.frmAddEdit.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();
  }

  addPhoneFieldGroup(phone: string = '') {
    const formControl = <FormArray>this.frmAddEdit.controls['phone'];
    formControl.push(this.formBuilder.group({
      phone: [phone],
    }));
  }

  removePhoneFieldGroup(idx: number) {
    const formControl = <FormArray>this.frmAddEdit.controls['phone'];
    formControl.removeAt(idx);
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmAddEdit, this.validationMessages);
  }

  save(timeOutInterval: number = 0) {
    this.googleMapSearchComponent.validateForm();
    this.validateForm();
    setTimeout(() => {
      if (this.frmAddEdit.valid) {
        const userInputs: any = this.frmAddEdit.value;
        const reqParams: any = {};
        reqParams.user_id = this.account.info.id;
        reqParams.auth_token = this.account.info.auth_token;
        reqParams.record_id = this.recordID;
        reqParams.action = (reqParams.record_id == 0) ? 'customer_create' : 'customer_update';
        reqParams.name = userInputs.name;
        reqParams.email = userInputs.email;
        reqParams.phone = userInputs.phone;
        reqParams.address = userInputs.address;
        reqParams.apartment = userInputs.apartment;
        reqParams.note = userInputs.note;
        reqParams.billing_status = userInputs.billing_status;
        reqParams.to_be_completed_by_admin = userInputs.to_be_completed_by_admin;
        reqParams.is_billing_manager = userInputs.is_billing_manager;
        reqParams.billing_manager_id = (userInputs.is_billing_manager === 0) ? userInputs.billing_manager : 0;
        reqParams.latitude = userInputs.latitude;
        reqParams.longitude = userInputs.longitude;

				// payment card data
				reqParams.paymentCards = this.paymentCards;

        this.helper.makeAPIRequest('customers/save', reqParams).then((response) => {
          if (response.success === 1) {
            this.helper.showNotification('success', 'Customer info saved!', this.helper.config.defaultSuccessTitle);
            this.helper.navBack();
          } else if (response.error === 1) {
            if (response.errorCode === 2) {
              this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
              this.account.logOut();
            } else if ([8,4,5,6].includes(response.errorCode)) {
              this.helper.showNotification('danger', response.message, '');
            } else {
              this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          } else {
            this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        }).catch((httpError) => {
          this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
        });
      }
    }, timeOutInterval);
  }

  onAddressChanged(address: string) {
    this.frmAddEdit.controls['address'].setValue(address);
  }

  onLatLongChanged(latLong: any) {
    this.frmAddEdit.controls['latitude'].setValue(latLong.latitude);
    this.frmAddEdit.controls['longitude'].setValue(latLong.longitude);
  }

	cardAction(action = 'add', cardListIndex = 0){
		if(action == 'add'){
			this.paymentCards.push({
				id: 0,
				card_number: '',
				card_exp: '',
				card_cvc: ''
			});
		}else{
			this.paymentCards.splice(cardListIndex, 1);
		}
	}

	cardNumberChange(number, cardListIndex){

		// Remove any non-digit characters from the input
		let sanitizedNumber = number.replace(/\D/g, '');

		// Enforce maximum 16 digits limit
		if (sanitizedNumber.length > 16) {
			sanitizedNumber = sanitizedNumber.slice(0, 16);
			this.cardNumberErrorMsg = 'Please enter valid card number.';
		} else if(sanitizedNumber.length === 16 || sanitizedNumber.length === 15){
			this.cardNumberErrorMsg = '';
		} else{
			this.cardNumberErrorMsg = 'Please enter valid card number.';
		}

		// Split the input into groups of four digits
		let formattedNumber = sanitizedNumber.replace(/(\d{4})/g, '$1 ');

		// Update the cardNumber property with the formatted value
		number = formattedNumber.trim();

		this.paymentCards[cardListIndex].card_number = number;
	}

	cardExpChange(numberExp, cardListIndex){

		// Remove any non-digit characters from the input
		let sanitizedExp = numberExp.replace(/\D/g, '');

		if (sanitizedExp.length > 2) {
			numberExp = sanitizedExp.slice(0, 2) + '/' + sanitizedExp.slice(2, 4);
		}
		if(sanitizedExp.length === 4){
			this.cardExpErrorMsg = '';
		}else{
			this.cardExpErrorMsg = 'Please enter valid card expiry.';
		}
		this.paymentCards[cardListIndex].card_exp = numberExp;
	}

	cardCVCChange(cvc, cardListIndex){
		if (cvc.length > 4) {
			this.paymentCards[cardListIndex].card_cvc = cvc.slice(0, 4);
			this.cardCVCErrorMsg = 'Please enter valid card cvc.';
			return false;
		}else{
			this.paymentCards[cardListIndex].card_cvc = cvc;
			if(cvc.length === 3 || cvc.length === 4){
				this.cardCVCErrorMsg = '';
			}
		}
	}

	removeCardConfirm(cardId){
		this.cardRemoveId = cardId;
		this.removeCardConfirmModal.show();
	}

	removeCard(){
		const reqParams: any = {};
		reqParams.user_id = this.account.info.id;
		reqParams.auth_token = this.account.info.auth_token;

		reqParams.card_payment_id = this.cardRemoveId;

		this.helper.makeAPIRequest('customers/remove_card', reqParams).then((response) => {
			if (response.success === 1) {
				this.helper.showNotification('success', 'Card Removed!', this.helper.config.defaultSuccessTitle);
				this.savedPaymentCards = this.savedPaymentCards.filter(card => card.id != this.cardRemoveId);
				this.cardRemoveId = 0;
				this.removeCardConfirmModal.hide();
			} else if (response.error === 1) {
				if (response.errorCode === 2) {
					this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
					this.account.logOut();
				} else if ([5].includes(response.errorCode)) {
					this.helper.showNotification('danger', response.message, '');
				} else {
					this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
				}
			} else {
				this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
			}
		}).catch((httpError) => {
			this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
		});
	}

}

