import { Injectable } from '@angular/core';

import { IdeateHelper } from '../core/services/ideate';
import { Account } from './account';
import { Observable, Subject } from 'rxjs';
import { CommonVM } from '../core/models/common-vm';
import { TicketStatusVM } from '../core/models/ticket-status-vm';

@Injectable()
export class App {
	public userRoles: CommonVM[] = [];
	public ticketStatusTypes: TicketStatusVM[] | any = [];
	public billingTypes: CommonVM[] = [];
	public billingStatusTypes: CommonVM[] = [];
	public customerBillingStatusTypes: CommonVM[] = [];
	public billingMethods: CommonVM[] = [];
	public priorityTypes: CommonVM[] = [];
	public paymentStatusTypes: CommonVM[] = [];
	public contractBillingStatusTypes: CommonVM[] = [];
	public contractServiceStatusTypes: CommonVM[] = [];
	public settings: any;
	public ticketFilters: any = {};

	constructor(
		public ideateHelper: IdeateHelper,
		public account: Account
	) {

		this.userRoles = [
			{ label: 'Admin', value: 'admin' },
			{ label: 'Mechanic', value: 'mechanic' }
		];

		this.ticketStatusTypes = [
			{ label: 'Open', value: 'open', color: 'red' },
			{ label: 'Assigned', value: 'assigned', color: 'purple' },
			{ label: 'Confirmed', value: 'confirmed', color: 'blue' },
			{ label: 'Started', value: 'started', color: 'yellow' },
			{ label: 'Completed', value: 'completed', color: 'green' },
			{ label: 'Cancelled', value: 'cancelled', color: 'grey' }
		];

		this.billingTypes = [
			{ label: 'Fixed', value: 'fixed' },
			{ label: 'Hourly', value: 'hourly' },
		];

		this.billingStatusTypes = [
			{ label: 'Bill', value: 'bill' },
			{ label: 'Collect', value: 'collect' },
			{ label: 'Repeat', value: 'repeat' },
			{ label: 'YC', value: 'yc' },
			{ label: 'Temp Bill', value: 'temp_bill' }
		];

		this.customerBillingStatusTypes = [
			{ label: 'Bill', value: 'bill' },
			{ label: 'Collect', value: 'collect' }
		];

		this.billingMethods = [
			{ label: 'Cash', value: 'cash' },
			{ label: 'Check', value: 'check' },
			{ label: 'New Card', value: 'card' }
		];

		this.priorityTypes = [
			{ label: 'Low', value: 'low' },
			{ label: 'Normal', value: 'normal' },
			{ label: 'High', value: 'high' },
			{ label: 'Emergency', value: 'emergency' }
		];

		this.paymentStatusTypes = [
			{ label: 'Unpaid', value: 'unpaid' },
			{ label: 'Submitted', value: 'submitted' },
			{ label: 'Paid', value: 'paid' }
		];

		this.contractBillingStatusTypes = [
			{ label: 'YC Bill', value: 'yc_bill' },
			{ label: 'YC Collect', value: 'yc_collect' },
			{ label: 'YS Bill', value: 'ys_bill' },
			{ label: 'YS Collect', value: 'ys_collect' }
		];

		this.contractServiceStatusTypes = [
			{ label: 'Pending', value: 'pending' },
			{ label: 'Ticket Created', value: 'ticket_created' },
			{ label: 'Cancelled', value: 'cancelled' }
		];

		const settings = localStorage.getItem('siteSettings');
		this.settings = (this.ideateHelper.isValidJSON(settings)) ? JSON.parse(settings) : {};

		this.ticketFilters = { default: 1 };
	}

	public getTypeAheadObservableOptions(form: any, userInput: string, apiEndPoint: string, params: any = {}): Observable<any> {
		return Observable.create((observer: any) => {
			const userInputs: any = form.value;
			observer.next(userInputs[userInput]);
		}).mergeMap((keyword: string) => {
			let reqParams: any = {};
			reqParams.user_id = this.account.info.id;
			reqParams.auth_token = this.account.info.auth_token;
			reqParams.keyword = keyword;
			reqParams = { ...reqParams, ...params };
			return this.ideateHelper.makeAPIRequest(apiEndPoint, reqParams).then((response) => {
				let items: any[] = [];
				if (response.success === 1) {
					items = response.data.record;
				}
				return items;
			}).catch((httpError) => {
				return [];
			});
		});
	}

	getNGMTypeAheadObservableOptions(ngModel: any, apiEndPoint: string, params: any = {}): Observable<any> {
		return Observable.create((observer: any) => {
			observer.next(ngModel);
		}).mergeMap((keyword: string) => {
			let reqParams: any = {};
			reqParams.user_id = this.account.info.id;
			reqParams.auth_token = this.account.info.auth_token;
			reqParams.keyword = keyword;
			reqParams = { ...reqParams, ...params };
			return this.ideateHelper.makeAPIRequest(apiEndPoint, reqParams).then((response) => {
				let items: any[] = [];
				if (response.success === 1) {
					items = response.data.record;
				}
				return items;
			}).catch((httpError) => {
				return [];
			});
		});
	}
}