import { Component, OnInit, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-map-locations',
  templateUrl: './map-locations.component.html',
  styleUrls: ['./map-locations.component.scss']
})
export class MapLocationsComponent implements OnInit, OnChanges {
  
  @Input() places: any;
  @Output() assignToTicket: EventEmitter<any> = new EventEmitter();

  public zoom: number;
  public map: any;

  constructor(private mapsAPILoader: MapsAPILoader, public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.zoom = 12;
  }

  ngOnChanges(): void { }

  assign(ticketID: number) {
    this.assignToTicket.emit(ticketID);
    this.bsModalRef.hide();
  }

  setMapCenter(latitude, longitude) {
    if (this.map) {
      this.map.setCenter({ lat: +latitude, lng: +longitude });
    }
  }

  fitMapBounds() {
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < this.places.length; i++) {
      bounds.extend(new google.maps.LatLng(this.places[i].location.latitude, this.places[i].location.longitude));
    }
    this.map.fitBounds(bounds);
  }

  mapReady(map) {
    this.map = map;
    if (this.places && this.places.length > 0) {
      // this.setMapCenter(this.places[0].location.latitude, this.places[0].location.longitude);
      this.fitMapBounds();
    }
  }

}
