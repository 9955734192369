import { Component, OnInit, Output, EventEmitter, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimePickerComponent implements OnInit, OnChanges {

  public formTimePicker: FormGroup;
  @Input() time: string;
  @Output() timeChanged = new EventEmitter<any>();
  @Output() hoursChanged = new EventEmitter<any>();
  @Output() minutesChanged = new EventEmitter<any>();
  @Output() meridianChanged = new EventEmitter<any>();
  public hoursOptions = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  public minutesOptions = ['05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '00'];
  public meridianOptions = ['AM', 'PM'];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges(): void {
    this.initForm();
  }

  initForm() {
    let hours = '12';
    let minutes = '00';
    let meridian = 'AM';
    if (this.time.length === 8 && (this.time.split(':').length - 1) === 1 && (this.time.split(' ').length - 1) === 1) {
      let time = this.time.split(' ');
      meridian = time[1];
      time = time[0].split(':');
      hours = time[0];
      minutes = time[1];
    }

    this.formTimePicker = this.formBuilder.group({
      hours: [hours],
      minutes: [minutes],
      meridian: [meridian]
    });

    this.formTimePicker.get('hours').valueChanges.subscribe(() => {
      const currHours = this.formTimePicker.get('hours').value;
      this.hoursChanged.emit(currHours);
      this.evTimeUpdated();
    });
    this.formTimePicker.get('minutes').valueChanges.subscribe(() => {
      const currMinutes = this.formTimePicker.get('minutes').value;
      this.minutesChanged.emit(currMinutes);
      this.evTimeUpdated();
    });
    this.formTimePicker.get('meridian').valueChanges.subscribe(() => {
      const currMeridian = this.formTimePicker.get('meridian').value;
      this.meridianChanged.emit(currMeridian);
      this.evTimeUpdated();
    });
  }

  evTimeUpdated() {
    const currHours = this.formTimePicker.get('hours').value;
    const currMinutes = this.formTimePicker.get('minutes').value;
    const currMeridian = this.formTimePicker.get('meridian').value;
    this.time = currHours + ':' + currMinutes + ' ' + currMeridian;
    this.timeChanged.emit(this.time);
  }

}
