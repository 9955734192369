import { Pipe, PipeTransform } from '@angular/core';
import { App } from '../providers/app';

@Pipe({
  name: 'billingStatus'
})
export class BillingStatusPipe implements PipeTransform {

  constructor(private app: App) { }

  transform(value: any): any {
    let billingStatusLabel = '';
    const billingStatus = this.app.billingStatusTypes.filter((item: any) => (item.value === value));
    if (billingStatus.length === 1) {
      billingStatusLabel = billingStatus[0].label;
    }
    return billingStatusLabel;
  }

}
