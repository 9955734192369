import { Pipe, PipeTransform } from '@angular/core';
import { App } from '../providers/app';

@Pipe({
  name: 'contractServiceStatus'
})
export class contractServiceStatusPipe implements PipeTransform {

  constructor(private app: App) { }

  transform(value: any): any {
    let contractServiceStatusTypesLabel = '';
    const contractServiceStatus = this.app.contractServiceStatusTypes.filter((item: any) => (item.value === value));
    if (contractServiceStatus.length === 1) {
      contractServiceStatusTypesLabel = contractServiceStatus[0].label;
    }
    return contractServiceStatusTypesLabel;
  }

}