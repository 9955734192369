import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';

@Component({
  selector: 'app-corporate-layout',
  templateUrl: './corporate.component.html',
  styleUrls: ['./corporate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CorporateLayout extends RootLayout implements OnInit {

  menuLinks = [
    {
      label: 'Dashboard',
      routerLink: '/',
      iconType: 'fa',
      iconName: 'home',
      classes: (['admin'].indexOf(this.account.info.type) === -1) ? 'd-none' : ''
    }, {
      label: 'Users',
      iconType: 'fa',
      iconName: 'user',
      toggle: 'close',
      classes: (['admin'].indexOf(this.account.info.type) === -1) ? 'd-none' : '',
      submenu: [
        {
          label: 'Manage',
          iconType: 'fa',
          iconName: 'list',
          routerLink: '/users',
        },
        {
          label: 'Add New',
          iconType: 'fa',
          iconName: 'plus',
          routerLink: '/users/add',
        },
      ]
    },
    {
      label: 'Services',
      iconType: 'fa',
      iconName: 'user',
      toggle: 'close',
      classes: (['admin'].indexOf(this.account.info.type) === -1) ? 'd-none' : '',
      submenu: [
        {
          label: 'Manage',
          iconType: 'fa',
          iconName: 'list',
          routerLink: '/services',
        },
        {
          label: 'Add New',
          iconType: 'fa',
          iconName: 'plus',
          routerLink: '/services/add',
        },
      ]
    },
    {
      label: 'Customers',
      iconType: 'fa',
      iconName: 'user',
      toggle: 'close',
      classes: (['admin'].indexOf(this.account.info.type) === -1) ? 'd-none' : '',
      submenu: [
        {
          label: 'Manage',
          iconType: 'fa',
          iconName: 'list',
          routerLink: '/customers',
        },
        {
          label: 'Add New',
          iconType: 'fa',
          iconName: 'plus',
          routerLink: '/customers/add',
        },
      ]
    }
  ];

  ngOnInit() {
    this.changeLayout('menu-pin');
    this.changeLayout('menu-behind');
    // Will sidebar close on screens below 1024
    this.autoHideMenuPin();
  }

}
