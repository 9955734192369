import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'social-image-tile-widget',
  templateUrl: './social-image-tile-widget.component.html',
  styleUrls: ['./social-image-tile-widget.component.scss']
})
export class SocialImageTileWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
