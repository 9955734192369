import { Pipe, PipeTransform } from '@angular/core';
import { App } from '../providers/app';

@Pipe({
  name: 'openingNote'
})
export class OpeningNotePipe implements PipeTransform {

  constructor(private app: App) { }

  transform(value: any): any {
    const transformedVal = value.replace(/\n/g, '<hr class=\'my-0 mx-3\'/>');
    return transformedVal;
  }

}
