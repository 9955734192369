import { Pipe, PipeTransform } from '@angular/core';
import { App } from '../providers/app';

@Pipe({
  name: 'contractBillingStatus'
})
export class ContractBillingStatusPipe implements PipeTransform {

  constructor(private app: App) { }

  transform(value: any): any {
    let contractBillingStatusLabel = '';
    const contractBillingStatus = this.app.contractBillingStatusTypes.filter((item: any) => (item.value === value));
    if (contractBillingStatus.length === 1) {
      contractBillingStatusLabel = contractBillingStatus[0].label;
    }
    return contractBillingStatusLabel;
  }

}
